import Vue from 'vue'
import Axios from 'axios'
import { DropdownMenu, DropdownItem, Field, Toast, Dialog } from 'vant'
import { mapState, mapActions } from 'vuex'
import { Empty } from 'vant'

Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Empty)
Vue.use(Toast)
Vue.use(Field)
Vue.use(Dialog)
export default {
  data () {
    return {
      changeColor: false,
      insList: [],
      shopName: '',//关键字搜索
      time: '',//最新上架时间
      istime: true,
      isclear: false,
      isshow: true,
    }
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created () {
    this.List()

  },
  beforeUpdate () {
  },
  methods: {
    ...mapActions('green', ['greenList']),
    back () {
      window.history.go(-1)
    },
    // 详情
    godetail (id) {
      this.$router.push({ name: 'greenDetail', query: { pid: id } })
    },
    List () {
      this.greenList().then((res) => {
        if (res.code == 200) {
          this.insList = res.data
          console.log(res)
        }

      })
    },
    clearAll () {
      this.shopName = ''
      this.isclear = false
      if (this.istime == true) {
        this.newest()
      } else {
        this.lowest()
      }

    },
    search () {
      if (this.shopName != '') {
        this.isclear = true
      } else {
        this.isclear = false
      }


      if (this.istime == true) {
        this.newest()
      } else {
        this.lowest()
      }
    },
    //  最新上架
    newest () {

      this.changeColor = false
      this.istime = true
      this.greenList({
        equityCardCommodityHot: 0,
        equityCardCommodityName: this.shopName
      }).then((res) => {
        if (res.code == 200) {
          this.insList = res.data
          console.log(res)
        }

      })


    },
    //   价格最低
    lowest () {
      this.changeColor = true
      this.istime = false

      this.greenList({
        equityCardCommodityPrice: 0,
        equityCardCommodityHot: 1,
        equityCardCommodityName: this.shopName
      }).then((res) => {
        if (res.code == 200) {
          this.insList = res.data

        }

      })


    },

  },
}
